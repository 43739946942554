<template>
	<div>
		<div
			v-if="showHideButton"
			class="div-icon"
		>
			<ChevronUpIcon
				v-if="show"
				color="var(--cal-header-color)"
				class="button-arrow"
				@click="handleShowTotales"
			/>
			<ChevronDownIcon
				v-else
				color="var(--cal-header-color)"
				class="button-arrow"
				@click="handleShowTotales"
			/>
		</div>
		<div
			v-if="show && options && options.vencidos"
			class="div-totales"
		>
			<div
				class="main-totales"
				v-bind:class="showAccountBalance ? 'main-totales-grid-columns-5' : ''"
			>
				<h2 class="bold head"></h2>
				<h2 class="bold head"></h2>
				<h2 class="derecha bold head">Receitas</h2>
				<h2 class="derecha bold head">Pagamentos</h2>
				<h2 class="derecha bold head" v-if="showAccountBalance">Saldo Contas</h2>

				<h2 class="rojo">Vencidos</h2>
				<h2 class="rojo">:</h2>
				<h2 class="rojo derecha">{{ options.vencidos.receive }}</h2>
				<h2 class="rojo derecha">{{ options.vencidos.pay }}</h2>
				<h2 class="rojo derecha" v-if="showAccountBalance"></h2>

				<h2>À Vencer</h2>
				<h2>:</h2>
				<h2 class="derecha">{{ options.aVencer.receive }}</h2>
				<h2 class="derecha">{{ options.aVencer.pay }}</h2>
				<h2 class="derecha" v-if="showAccountBalance"></h2>

				<h2>Pago</h2>
				<h2>:</h2>
				<h2 class="derecha">{{ options.pagados.receive }}</h2>
				<h2 class="derecha">{{ options.pagados.pay }}</h2>
				<h2 class="derecha" v-if="showAccountBalance"></h2>

				<h2 class="bold">Total</h2>
				<h2 class="bold">:</h2>
				<h2 class="derecha bold">{{ options.mes.receive }}</h2>
				<h2 class="derecha bold">{{ options.mes.pay }}</h2>
				<h2 class="derecha bold cursor-hand"
					v-if="showAccountBalance"
					@click="viewAccountBalance"
				>
					{{ options.mes.balance }}
				</h2>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TotalsMonth",
		component: {
		},

		props: {
			options: {
				type: Object,
				required: false
			},
			show: {
				type: Boolean,
				required: false,
				default: false
			},
			showAccountBalance: {
				type: Boolean,
				required: false,
				default: false
			},
			isLoading: {
				type: Boolean,
				required: false,
				default: false
			},
			showHideButton: {
				type: Boolean,
				required: false,
				default: true
			}
		},

		methods: {
			handleShowTotales () {
				this.$emit("click");
			},

			viewAccountBalance () {
				this.$router.push({ path: "/report/AccountBalance"});
			}
		}
	};
</script>

<style scoped>
@keyframes vertical-slide {
	0% {
		transform: translateY(0);
		opacity: 0.5;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.div-icon {
	text-align: center;
	margin-top: -20px;
}
.div-totales {
	display: flex;
	justify-content: center;
	animation: vertical-slide 500ms;
}

h2 {
  font-size: 1.4rem;
}

.main-totales {
	display: grid;
	width: 35rem;
	grid-template-columns: 5rem 2px 1fr 1fr;
	column-gap: 10px;
	background: azure;
	border-style: solid;
	border-color: var(--color-button-grey-hover);
	border-width: 1px;
	padding: 8px 8px 0 8px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	background-color: white;
	margin-bottom: 2px;
	margin-top: 2px;
}

.main-totales-grid-columns-5 {
	width: 40rem;
	grid-template-columns: 5rem 2px 1fr 1fr 1fr !important;
}

.cursor-hand {
	cursor: pointer;
}

.col-1 {
	padding-right: 8px;
}

.rojo {
	color: red;
}

.derecha {
	text-align: right;
}

.bold {
	font-weight: 600;
	background-color: rgb(215, 238, 247);
	margin-left: -8px;
	padding: 6px 8px 0 8px;
	width: calc(100% + 16px);
	margin-bottom: 0px;
}

.head {
	margin-top: -10px;
	padding-bottom: 5px;
}

.button-arrow {
	width: 40px;
	height: 30px;
	border-radius: 8px;
	background: var(--color-button-grey);
	border-style: solid;
	border-color: var(--color-button-grey-hover);
	border-width: 1px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}

.button-arrow:hover {
  background: var(--color-button-grey-hover);
}

@media (max-width: 720px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 1.15rem;
	}
}

@media (max-width: 370px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 0.9rem;
	}
}

@media (max-width: 325px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 0.8rem;
	}
}

@media (max-width: 310px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 0.7rem;
	}
}
</style>
